<template>
    <div class="share-step-two">
        <ShWidgetStepper
            :model="kind === 'chat' ? 'mini' : 'normal'"
            :step="kind === 'chat' ? 1 : 2"
            class="mt-6"
        />
        <div class="share-step-two__wrapper">
            <main>
                <div class="main__head">
                    <h1>
                        {{ $lang.components.cardShareStepTwo.share }}
                        {{ type.wordSingularOne }}
                    </h1>
                    <NuxtLink
                        no-prefetch
                        v-if="type.variant === 'offer'"
                        :to="`/${$lang.routes.share}/${$lang.routes.coupon}`"
                        rel="follow"
                        >{{ $lang.components.cardShareStepTwo.share_coupon }}</NuxtLink
                    >
                    <NuxtLink
                        no-prefetch
                        v-if="type.variant === 'coupon'"
                        :to="`/${$lang.routes.share}/${$lang.routes.offer}`"
                        rel="follow"
                        >{{ $lang.components.cardShareStepTwo.share_offer }}</NuxtLink
                    >
                </div>

                <form @submit.prevent="handleNext">
                    <div class="main__formulary">
                        <div
                            v-if="type.variant !== 'chat'"
                            id="url"
                            class="main__formulary-wrap"
                            @click="$emit('setValidations', 'url')"
                        >
                            <div class="main__formulary-wrap-title">
                                {{ $lang.components.cardShareStepTwo.link }} {{ kindtext }}
                                <span class="main__formulary-wrap-title-optional">
                                    {{ `${$lang.components.cardShareStepTwo.optional}` }}
                                </span>
                            </div>

                            <InputGeneric
                                v-model.trim="formData.url"
                                :image="$assets.primary.link"
                                bgcolor="light"
                                placeholder="https://www.amazon.com.mx/deal/5ba199d6"
                                :invalid="validationStatus.url"
                            >
                                <!-- @blur="getImagesUsingScrapper" -->
                                {{ validationStatus.url || $lang.components.cardShareStepTwo.error_field }}
                            </InputGeneric>
                            <div class="main__formulary-wrap-gallery">
                                <div class="main__formulary-wrap-gallery-title">
                                    {{ $lang.components.cardShareStepTwo.images_from_link }}
                                </div>
                                <div class="main__formulary-wrap-gallery-wrapper">
                                    <div
                                        v-for="(image, key) in formData.imagesUploaded"
                                        :key="key"
                                        class="images"
                                        :class="
                                            formData.imageActive === key
                                                ? 'ring-2 ring-site-primary-hover-light'
                                                : ''
                                        "
                                    >
                                        <picture @click="makeImageActive(key)"
                                            ><img
                                                v-if="image.dataUrl"
                                                :src="image.dataUrl"
                                                alt="imagen subida por usuario"
                                        /></picture>
                                        <button
                                            v-if="key >= (useDefaultImage ? 1 : 0)"
                                            type="button"
                                            @click="removeImage(key)"
                                        >
                                            <img class="close" :src="$assets.gray.close" alt="delete" />
                                        </button>
                                    </div>

                                    <div class="upload">
                                        <img
                                            :src="$assets.primary.photoUpload"
                                            :alt="$lang.components.cardShareStepTwo.upload_images_here"
                                        />
                                        <span>{{
                                            $lang.components.cardShareStepTwo.upload_images_here
                                        }}</span>
                                        <input
                                            type="file"
                                            accept="image/x-png,image/jpeg"
                                            class="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                                            @change="uploadImage"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            v-if="type.variant === 'offer'"
                            id="new_price"
                            class="main__formulary-wrap"
                            @click="$emit('setValidations', 'new_price')"
                        >
                            <label class="main__formulary-wrap-title">
                                {{ $lang.components.cardShareStepTwo.offer_details }}
                                <span class="main__formulary-wrap-title-optional">
                                    ({{ $lang.components.cardShareStepTwo.optional }})
                                </span>
                            </label>
                            <ShInputPrice
                                :priceoffer.sync="offer_details.price_offer"
                                :priceregular.sync="offer_details.regular_price"
                                :shipcost.sync="offer_details.ship_costs"
                                :freeship.sync="offer_details.freeship"
                                :invalid="validationStatus.new_price"
                                @update:priceoffer="offer_details.price_offer = $event"
                                @update:priceregular="offer_details.regular_price = $event"
                                @update:shipcost="offer_details.ship_costs = $event"
                                @update:freeship="offer_details.freeship = $event"
                            >
                                {{
                                    validationStatus.new_price &&
                                    $lang.components.cardShareStepTwo.error_field
                                }}
                            </ShInputPrice>
                        </div>

                        <div
                            v-if="kind === 'coupon'"
                            id="discount_value"
                            class="wrap"
                            @click="$emit('setValidations', 'discount_value')"
                        >
                            <label class="main__formulary-wrap-title">
                                {{ $lang.components.cardShareStepTwo.discount_type }}
                                <span class="main__formulary-wrap-title-optional">
                                    ({{ $lang.components.cardShareStepTwo.optional }})
                                </span>
                            </label>
                            <ShInputTabs
                                :placeholder="placeholder.description"
                                :invalid="validationStatus.discount_value"
                                @change="updateDiscount"
                            >
                                {{
                                    validationStatus.discount_value ||
                                    $lang.components.cardShareStepTwo.error_field
                                }}
                            </ShInputTabs>
                        </div>

                        <div
                            v-if="kind === 'offer' || kind === 'coupon'"
                            class="main__formulary-wrap"
                            @click="$emit('setValidations', 'code')"
                        >
                            <label class="main__formulary-wrap-title">
                                {{
                                    kind === 'offer'
                                        ? $lang.components.cardShareStepTwo.offer_code
                                        : $lang.components.cardShareStepTwo.coupon_code
                                }}
                                <span class="main__formulary-wrap-title-optional">
                                    ({{ $lang.components.cardShareStepTwo.optional }})
                                </span>
                            </label>
                            <InputGeneric
                                v-model="formData.code"
                                :image="$assets.primary.dashOffersAndCoupons"
                                imagestyle="h-5 top-3"
                                class="form-login__field"
                                bgcolor="light"
                                type="text"
                                name="username"
                                :placeholder="$lang.components.cardShareStepTwo.coupon_to_use"
                                :invalid="validationStatus.code"
                            >
                                {{ validationStatus.code || $lang.components.cardShareStepTwo.error_field }}
                            </InputGeneric>
                        </div>

                        <div
                            id="title"
                            class="main__formulary-wrap"
                            @click="$emit('setValidations', 'title')"
                        >
                            <label class="main__formulary-wrap-title">
                                {{ $lang.components.cardShareStepTwo.title }}
                                <span class="main__formulary-wrap-title-optional">
                                    ({{ $lang.components.cardShareStepTwo.required }})
                                </span>
                            </label>
                            <InputGeneric
                                v-model.trim="formData.title"
                                bgcolor="light"
                                class="form-login__field"
                                type="text"
                                :disabled="loading"
                                name="username"
                                :placeholder="$lang.components.cardShareStepTwo.set_offer_details"
                                :invalid="validationStatus.title"
                                @blur="validateTitle"
                            >
                                {{ validationStatus.title || $lang.components.cardShareStepTwo.error_field }}
                            </InputGeneric>
                            <div class="main__formulary-wrap-details">
                                {{ $lang.components.cardShareStepTwo.max_140_chars }}
                            </div>
                        </div>

                        <div
                            id="description"
                            class="main__formulary-wrap"
                            @click="$emit('setValidations', 'description')"
                        >
                            <label class="main__formulary-wrap-title">
                                {{ $lang.components.cardShareStepTwo.description }}
                                <span class="main__formulary-wrap-title-optional">
                                    ({{ $lang.components.cardShareStepTwo.required }})
                                </span>
                            </label>

                            <ClientOnly>
                                <template #fallback>
                                    <!-- this will be rendered on server side -->
                                    <div
                                        class="flex h-[84px] items-center justify-center rounded-xl bg-gray-100 text-sm font-semibold text-gray-400"
                                    >
                                        Loading...
                                    </div>
                                </template>
                                <InputEditor
                                    v-model="formData.description"
                                    placeholder="Agregar una descripcion..."
                                    editor-id="description-editor"
                                ></InputEditor>
                            </ClientOnly>

                            <div v-if="validationStatus.description" class="error mt-4">
                                <img :src="$assets.red.formError" alt="icon" width="16" height="16" />
                                <p>
                                    {{
                                        validationStatus.description ||
                                        $lang.components.cardShareStepTwo.error_field
                                    }}
                                </p>
                            </div>
                        </div>

                        <div
                            v-if="
                                dropdownCategory.ready &&
                                (type.variant === 'offer' ||
                                    type.variant === 'coupon' ||
                                    type.variant === 'chat')
                            "
                            :id="kind === 'chat' ? 'forum_category_id' : 'category_id'"
                            class="main__formulary-wrap relative"
                            @click="
                                $emit('setValidations', kind === 'chat' ? 'forum_category_id' : 'category_id')
                            "
                        >
                            <label class="main__formulary-wrap-title">
                                {{ $lang.components.cardShareStepTwo.category }}
                                <span class="main__formulary-wrap-title-optional">
                                    ({{ $lang.components.cardShareStepTwo.required }})
                                </span>
                            </label>
                            <div class="main__formulary-wrap-subtitle">
                                {{ $lang.components.cardShareStepTwo.select_category }}
                            </div>

                            <div class="relative">
                                <button
                                    type="button"
                                    class="main__formulary-wrap-dropdown"
                                    @click="dropdownToggle(!dropdownCategory.show, 'category')"
                                >
                                    <div
                                        class="title"
                                        :class="
                                            validationStatus[
                                                kind === 'chat' ? 'forum_category_id' : 'category_id'
                                            ]
                                                ? 'mb-3 border-red-500 ring-1 ring-red-500'
                                                : ''
                                        "
                                    >
                                        <p>
                                            {{
                                                kind === 'chat'
                                                    ? dropdownForumCategories.selected.name
                                                    : dropdownCategory.selected.name
                                            }}
                                        </p>
                                        <img
                                            class="icon-drop"
                                            width="16"
                                            height="16"
                                            :class="
                                                dropdownCategory.show
                                                    ? 'rotate-180 transition-all'
                                                    : 'transition-all'
                                            "
                                            :src="$assets.black.dropdown"
                                            alt="dropdown"
                                        />
                                    </div>
                                    <div
                                        v-if="
                                            validationStatus[
                                                kind === 'chat' ? 'forum_category_id' : 'category_id'
                                            ]
                                        "
                                        class="error"
                                    >
                                        <img :src="$assets.red.formError" alt="icon" width="16" height="16" />
                                        <p>{{ $lang.components.cardShareStepTwo.error_field }}</p>
                                    </div>
                                </button>
                                <Transition name="fade">
                                    <ShWidgetSelect
                                        v-if="dropdownCategory.show"
                                        :handle-close="() => dropdownToggle(false, 'category')"
                                        classes="left-0 pt-1"
                                        :options.sync="dropdownSelect"
                                        :invalid="
                                            validationStatus[
                                                kind === 'chat' ? 'forum_category_id' : 'category_id'
                                            ]
                                        "
                                    />
                                </Transition>
                            </div>
                        </div>

                        <div
                            v-if="kind !== 'chat'"
                            class="main__formulary-wrap"
                            @click="$emit('setValidations', 'start_at')"
                        >
                            <label class="main__formulary-wrap-title">
                                {{ $lang.components.cardShareStepTwo.start_date }}
                                <span class="main__formulary-wrap-title-optional">
                                    {{
                                        kind === 'offer' || kind === 'coupon'
                                            ? `(${$lang.components.cardShareStepTwo.optional})`
                                            : `(${$lang.components.cardShareStepTwo.required})`
                                    }}
                                </span>
                            </label>
                            <VueDatePicker
                                v-model="datePickerStart"
                                class="max-w-52"
                                :start-time="{ hours: 0, minutes: 0 }"
                                :ui="{
                                    input: 'custom-input-date-styles',
                                    menu: 'custom-menu-date-styles',
                                }"
                            ></VueDatePicker>

                            <div v-if="validationStatus.start_at" class="error">
                                <img :src="$assets.red.formError" alt="icon" width="16" height="16" />
                                <p>
                                    {{
                                        validationStatus.start_at ||
                                        $lang.components.cardShareStepTwo.error_fields
                                    }}
                                </p>
                            </div>
                        </div>

                        <div v-if="kind !== 'chat'" class="main__formulary-wrap">
                            <label class="main__formulary-wrap-title">
                                {{ $lang.components.cardShareStepTwo.end_date }}
                                <span class="main__formulary-wrap-title-optional">
                                    ({{ $lang.components.cardShareStepTwo.optional }})
                                </span>
                            </label>
                            <VueDatePicker
                                v-model="datePickerEnd"
                                class="max-w-52"
                                :start-time="{ hours: 23, minutes: 59 }"
                                :ui="{
                                    input: 'custom-input-date-styles',
                                    menu: 'custom-menu-date-styles',
                                }"
                            ></VueDatePicker>
                        </div>

                        <div
                            v-if="
                                dropdownCountry.ready &&
                                (type.variant === 'offer' || type.variant === 'coupon')
                            "
                            class="main__formulary-wrap relative"
                        >
                            <div class="main__formulary-wrap-title">
                                {{ $lang.components.cardShareStepTwo.where_send }}
                                <span class="main__formulary-wrap-title-optional">
                                    ({{ $lang.components.cardShareStepTwo.optional }})
                                </span>
                            </div>

                            <div class="relative">
                                <button
                                    type="button"
                                    class="main__formulary-wrap-dropdown"
                                    @click="dropdownToggle(!dropdownCountry.show, 'country')"
                                >
                                    <h3 class="title">
                                        <img
                                            class="icon"
                                            :src="$assets.gray.world"
                                            alt="icono del mundo"
                                            width="16"
                                            height="16"
                                        />
                                        {{ dropdownCountry.selected.name }}
                                        <img
                                            class="icon-drop"
                                            width="16"
                                            height="16"
                                            :class="
                                                dropdownCountry.show
                                                    ? 'rotate-180 transition-all'
                                                    : 'transition-all'
                                            "
                                            :src="$assets.black.dropdown"
                                            alt="dropdown"
                                        />
                                    </h3>
                                </button>
                                <Transition name="fade">
                                    <ShWidgetSelect
                                        v-if="dropdownCountry.show"
                                        :handle-close="() => dropdownToggle(false, 'country')"
                                        classes="w-64 left-0 pt-1"
                                        :options.sync="dropdownCountry"
                                    />
                                </Transition>
                            </div>
                        </div>

                        <div v-if="type.variant === 'offer' || type.variant === 'coupon'" class="wrap">
                            <InputCheckbox id="rememberMe" v-model="formData.isLocal">
                                {{
                                    type.variant === 'offer'
                                        ? $lang.components.cardShareStepTwo.local_offer
                                        : $lang.components.cardShareStepTwo.local_coupon
                                }}
                            </InputCheckbox>
                        </div>
                    </div>

                    <div class="main__action-buttons">
                        <button class="bg-site-primary" :disabled="loading" type="submit">
                            <img
                                v-show="loading"
                                :src="$assets.gray.loading"
                                height="17"
                                width="17"
                                alt="icon"
                                class="spin"
                            />
                            <span v-show="!loading"
                                >{{ $lang.components.cardShareStepTwo.send }} {{ type.wordSingular }}</span
                            >
                        </button>
                    </div>
                </form>
            </main>
            <aside>
                <h4>Vista previa</h4>
                <ShWidgetOfferAndCoupon
                    v-if="type.variant === 'coupon' || type.variant === 'offer'"
                    :carddata="{
                        formData,
                        kind,
                        offer_details,
                        discount,
                        datePicker: { end: datePickerEnd, start: datePickerStart },
                    }"
                    :button-type="buttonType as any"
                />
                <ShWidgetChat
                    v-if="type.variant === 'chat'"
                    :carddata="{ formData }"
                    :dropdown-selected="dropdownSelect.selected.name"
                />
            </aside>
        </div>
    </div>
</template>

<script lang="ts" setup>
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { useRootStore } from '~/store/root'
import type { Models } from '~/types/models'
import type { Api } from '~~/global'

import { useVariantsDictionary } from '~/composables/VariantsDictionary'
const { defaultImages, variants } = useVariantsDictionary()

const props = defineProps({
    kind: {
        type: String as PropType<'coupon' | 'offer' | 'chat'>,
        required: true,
    },
    kindtext: {
        type: String,
        required: true,
    },
    loading: {
        default: false,
        type: Boolean,
    },
    validationStatus: {
        type: Object,
        required: true,
    },
})

const type = computed(() => variants[props.kind])

const { $lang, $assets } = useNuxtApp()

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const useDefaultImage = ref(false)

const placeholder = reactive({
    description: $lang.components.cardShareStepTwo.description_long,
})

const dropdownCountry = ref({
    show: false,
    ready: false,
    selected: { name: $lang.components.cardShareStepTwo.select, id: null },
    data: [] as Models.Country[],
})
const dropdownCategory = ref({
    show: false,
    ready: false,
    selected: { name: $lang.components.cardShareStepTwo.select, id: null },
    data: [] as Models.Category[],
})
const dropdownForumCategories = ref({
    show: false,
    ready: false,
    selected: { name: $lang.components.cardShareStepTwo.select, id: null },
    data: [] as Models.ForumCategory[],
})
const dropdownParticipate = ref({
    show: false,
    ready: true,
    selected: { name: $lang.components.cardShareStepTwo.select, icon: null, id: 0 },
    data: [
        {
            id: 'Facebook',
            name: 'Facebook',
            icon: $assets.social.facebook,
        },
        {
            id: 'Instagram',
            name: 'Instagram',
            icon: $assets.social.instagram,
        },
        {
            id: 'Twitter',
            name: 'Twitter',
            icon: $assets.social.twitter,
        },
        {
            id: 'WebSite',
            name: $lang.components.cardShareStepTwo.website,
            icon: $assets.social.website,
        },
        {
            id: 'Other',
            name: $lang.components.cardShareStepTwo.other,
            icon: $assets.social.other,
        },
    ] as any,
})

const formData = reactive({
    imageActive: 0,
    imagesUploaded: [] as Array<{ dataUrl?: string | null; file?: File }>,
    url: '',
    offer_details: {
        price_offer: '',
        regular_price: '',
        ship_costs: '',
        freeship: false,
    },
    discount: {
        type: '',
        value: '',
    },
    code: '',
    title: '',
    description: '',
    categoryId: null,
    countryId: null,
    forumCategoryId: null,
    datePicker: {
        start: '',
        end: '',
    },
    isLocal: false,
    messages: 0,
    type: 'see-coupon',
})

const offer_details = reactive({
    price_offer: '',
    regular_price: '',
    ship_costs: '',
    freeship: false,
})

watch(
    offer_details,
    (val) => {
        formData.offer_details = val
    },
    { deep: true },
)

const discount = reactive({
    type: '',
    value: '',
})

const updateDiscount = (val: typeof discount) => {
    discount.type = val.type
    discount.value = val.value
}

watch(
    discount,
    (val) => {
        formData.discount = val
    },
    { deep: true },
)

const datePickerEnd = ref('')

watch(
    datePickerEnd,
    (val) => {
        formData.datePicker.end = val || ''
    },
    { deep: true },
)

const datePickerStart = ref('')

watch(
    datePickerStart,
    (val) => {
        formData.datePicker.start = val || ''
    },
    { deep: true },
)

const defaultImage = computed(() => {
    return type.value.variant === 'offer'
        ? defaultImages.offer
        : type.value.variant === 'coupon'
          ? defaultImages.coupon
          : ''
})

const buttonType = computed(() => {
    if (type.value.variant === 'offer') {
        return formData.url?.length > 0 && formData.code?.length > 0
            ? 'offer-code'
            : formData.url?.length > 0
              ? 'offer'
              : 'no-link-offer'
    } else {
        return formData.url?.length > 0 ? 'coupon' : 'no-link-coupon'
    }
})
const dropdownSelect = computed({
    get(): any {
        return props.kind === 'chat' ? dropdownForumCategories.value : dropdownCategory.value
    },
    set(newValue: any) {
        if (props.kind === 'chat') {
            dropdownForumCategories.value = newValue
        } else {
            dropdownCategory.value = newValue
        }
    },
})

const Route = useRoute()

const routeQuery = computed(() => Route.query)

watch(
    routeQuery,
    (val) => {
        formData.url = val.url as string
        formData.code = val.code as string
        if (val.images && val.images.length > 0) {
            const newImages = val.images as string[]
            formData.imagesUploaded = [...newImages.reverse().map((i: any) => ({ dataUrl: i }))]
        } else {
            formData.imagesUploaded = [{ dataUrl: defaultImage.value }]
            useDefaultImage.value = true
        }
    },
    { immediate: true },
)

const selectedDropdownCountry = computed(() => dropdownCountry.value.selected)

watch(selectedDropdownCountry, (val) => {
    if (val) {
        formData.countryId = val.id
    }
})
const selectedDropdownCategory = computed(() => dropdownCategory.value.selected)

watch(selectedDropdownCategory, (val) => {
    if (val) {
        formData.categoryId = val.id
    }
})
const selectedDropdownForumCategory = computed(() => dropdownForumCategories.value.selected)

watch(selectedDropdownForumCategory, (val) => {
    if (val) {
        formData.forumCategoryId = val.id
    }
})

const propKindComputed = computed(() => props.kind)

watch(
    propKindComputed,
    (val) => {
        if (val === 'offer') {
            formData.type = 'see-offer'
        }
    },
    {
        immediate: true,
    },
)

const formTitleComputed = computed(() => formData.title)
watch(
    formTitleComputed,
    (val) => {
        if (props.kind === 'offer') {
            if (
                val.includes('cupon') ||
                val.includes('cupón') ||
                formData.description.includes('cupon') ||
                formData.description.includes('cupón')
            ) {
                formData.type = 'see-coupon'
            } else {
                formData.type = 'see-offer'
            }
        }
    },
    { immediate: true },
)

const formDescComputed = computed(() => formData.description)
watch(
    formDescComputed,
    (val) => {
        if (props.kind === 'offer') {
            if (
                val.includes('cupon') ||
                val.includes('cupón') ||
                formData.description.includes('cupon') ||
                formData.description.includes('cupón')
            ) {
                formData.type = 'see-coupon'
            } else {
                formData.type = 'see-offer'
            }
        }
    },
    { immediate: true },
)

onMounted(async () => {
    const categories = await $fetch<Api.Responses.General.Categories>(endpoints.general.categories.path, {
        method: 'GET',
        baseURL,
        headers: buildHeaders(),
        params: {
            first_level: true,
            limit: 500,
            without_pagination: true,
        },
    })

    dropdownCategory.value.data.push(...categories.data.results)
    dropdownCategory.value.ready = true

    const forumCategories = await $fetch<Api.Responses.General.ForumCategories>(
        endpoints.general.forumCategories.path,
        {
            method: 'GET',
            baseURL,
            headers: buildHeaders(),
        },
    )

    dropdownForumCategories.value.data.push(...forumCategories.data.results)
    dropdownForumCategories.value.ready = true

    const countries = await $fetch<Api.Responses.General.Countries>(endpoints.general.countries.path, {
        method: 'GET',
        baseURL,
        headers: buildHeaders(),
    })

    dropdownCountry.value.data.push(...countries.data.results)
    dropdownCountry.value.ready = true
})

const emits = defineEmits(['setValidations', 'makePost'])
const RootStore = useRootStore()

const validateTitle = () => {
    if (formData.title.length < 40) {
        emits('setValidations', 'title', $lang.components.cardShareStepTwo.title_40_chars)
    }
}

const removeImage = (key: number) => {
    if (formData.imagesUploaded.length === 1) {
        formData.imagesUploaded = [{ dataUrl: defaultImage.value }]
        useDefaultImage.value = true
        makeImageActive(0)
    } else {
        formData.imagesUploaded.splice(key, 1)

        if (
            key < formData.imageActive ||
            (formData.imagesUploaded.length === key && key === formData.imageActive)
        ) {
            makeImageActive(formData.imageActive - 1)
        }
    }
}

const makeImageActive = (key: number) => {
    if (typeof key === 'number') {
        formData.imageActive = key
    }
}

const handleNext = () => {
    emits('makePost', formData)
}

const uploadImage = (ev: any) => {
    const image = ev.target.files[0] as File | undefined
    if (image) {
        if (
            image.type !== 'image/jpg' &&
            image.type !== 'image/jpeg' &&
            image.type !== 'image/png' &&
            image.type !== 'image/svg'
        ) {
            return RootStore.setSiteNotification({
                duration: 3000,
                dismissButtonText: $lang.components.cardShareStepTwo.hide,
                text: $lang.components.cardShareStepTwo.file_not_permitted,
                type: 'error',
            })
        } else if (image.size > 1000000) {
            return RootStore.setSiteNotification({
                duration: 3000,
                dismissButtonText: $lang.components.cardShareStepTwo.hide,
                text: $lang.components.cardShareStepTwo.size_exceeded,
                type: 'error',
            })
        } else {
            try {
                const reader = new FileReader()
                reader.readAsDataURL(image)
                reader.onload = (e) => {
                    if (useDefaultImage.value) {
                        formData.imagesUploaded = [{ file: image, dataUrl: e.target?.result as string }]
                        useDefaultImage.value = false
                    } else {
                        formData.imagesUploaded.push({ file: image, dataUrl: e.target?.result as string })
                    }
                }
            } catch (e) {
                console.log('Fail on reading image url')
            }
        }
    }
}

const dropdownToggle = (show: boolean, name: 'category' | 'country' | 'participate') => {
    if (name === 'category') {
        dropdownCategory.value = {
            ...dropdownCategory.value,
            show,
        }
    }
    if (name === 'country') {
        dropdownCountry.value = {
            ...dropdownCountry.value,
            show,
        }
    }
    if (name === 'participate') {
        dropdownParticipate.value = {
            ...dropdownParticipate.value,
            show,
        }
    }
}
</script>

<style lang="postcss" scoped>
.share-step-two {
    @apply container mb-10;
    &__wrapper {
        @apply mt-12 flex flex-col gap-y-6 lg:flex-row lg:gap-x-4;
        main {
            @apply rounded-2xl bg-white p-3 text-gray-800 lg:w-7/12;
            .main__head {
                @apply mb-4 flex items-center gap-3 font-semibold;
                h1 {
                    @apply text-2xl;
                }
                a {
                    @apply rounded-lg border border-site-primary p-2 text-center text-sm text-site-primary hover:bg-site-primary hover:text-white;
                }
            }
            .main__formulary {
                @apply flex flex-col space-y-7;
                &-wrap {
                    @apply flex flex-col space-y-1;
                    &-title {
                        @apply flex items-center gap-1 text-lg font-semibold text-gray-800;
                        &-optional {
                            @apply text-sm font-medium text-site-primary;
                        }
                    }
                    &-subtitle {
                        @apply text-gray-800;
                    }
                    &-details {
                        @apply text-sm font-medium text-site-primary;
                    }
                    &-dropdown {
                        .title {
                            @apply flex min-w-[208px] items-center justify-between rounded-xl border bg-[#F8F7FC] px-5 py-2 font-sans;
                            .icon {
                                @apply mr-1 h-4;
                            }
                            .icon-drop {
                                @apply ml-5 h-1.5;
                            }
                        }
                    }
                    &-gallery {
                        @apply mt-2 rounded-xl border bg-white p-2;
                        &-title {
                            @apply text-lg;
                        }
                        &-wrapper {
                            @apply mt-2 flex flex-wrap justify-center gap-2;
                            .images {
                                @apply relative h-28 w-1/3 cursor-pointer rounded-lg border md:w-1/5;
                                img {
                                    @apply mx-auto h-full w-full object-contain p-2 py-4;
                                }
                                .close {
                                    @apply absolute -right-1.5 -top-1.5 h-6 w-6 cursor-pointer rounded-full border bg-white p-1;
                                }
                            }
                            .upload {
                                @apply relative flex h-28 w-1/3 flex-col items-center justify-center gap-1 text-xs text-site-primary md:w-1/5;
                                span {
                                    @apply text-center;
                                }
                                img {
                                    @apply mx-auto;
                                }
                            }
                        }
                    }
                }
            }
            .main__action-buttons {
                @apply mt-5 flex justify-center sm:justify-start;
                button {
                    @apply flex w-full cursor-pointer justify-center rounded-xl border border-site-primary bg-site-primary px-4 py-2 text-base font-semibold text-white hover:bg-site-primary focus:outline-none sm:w-fit;
                    img {
                        @apply h-4 w-4;
                    }
                }
            }
        }
        aside {
            @apply top-20 block h-full space-y-2 lg:sticky lg:w-5/12;
            h4 {
                @apply text-xl font-semibold text-gray-800;
            }
        }
    }
}
.error {
    @apply flex items-center space-x-1.5 text-sm text-red-500;
    img {
        @apply w-4;
    }
}
</style>

<style lang="postcss">
.custom-menu-date-styles,
.custom-input-date-styles {
    @apply rounded-xl !important;
}
.custom-input-date-styles {
    @apply h-[42px] hover:border-site-primary !important;
}
</style>
