<template>
    <!-- https://i.imgur.com/5Uru26k.png -->
    <div ref="$select" class="dropdown-select" :class="classes">
        <div class="dropdown-select__wrapper" :class="invalid ? 'invalid' : ''">
            <ul class="dropdown-select__items" :class="invalid ? 'invalid' : ''">
                <li
                    v-for="(option, key) in options[kindData === 'chatData' ? 'chatData' : 'data']"
                    :key="key"
                    class="dropdown-select__item"
                >
                    <button
                        type="button"
                        class="dropdown-select__item-text"
                        :class="option.style ? option.style : 'text-gray-900'"
                        @click="
                            kindData === 'chatData'
                                ? emitChangeSelected(option.name, option.id)
                                : emitChangeSelected({ name: option.name, id: option.id })
                        "
                    >
                        <p :class="options.selected === option.title ? 'text-site-primary' : ''">
                            {{ kindData === 'chatData' ? option.name : option.name }}
                        </p>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts" setup>
type TDataOptions = {
    id?: number
    name?: string
    title?: string
    selected?: string
    icon?: string
    style?: string
}

interface IPropsOptions {
    chatData?: TDataOptions[]
    data?: TDataOptions[]
    selected?: any
}

const props = defineProps({
    options: {
        type: Object as PropType<IPropsOptions>,
        required: true,
    },
    classes: {
        type: String,
        default: '',
    },
    invalid: {
        type: [Boolean, String],
        default: false,
    },
    kindData: {
        type: String,
    },
    handleClose: {
        type: Function,
    },
})
const emits = defineEmits(['update:options'])

const emitChangeSelected = (newSelected: any, newImage: any = null) => {
    const newoption = props.options
    if (newImage) {
        newoption.selected = { title: newSelected, icon: newImage }
    } else {
        newoption.selected = { name: newSelected.name, id: newSelected.id }
    }
    emits('update:options', newoption)
    props.handleClose?.()
}
const $select = ref()
onClickOutside($select, () => props.handleClose?.())
</script>

<style lang="postcss" scoped>
.dropdown-select {
    @apply absolute top-[100%] z-50;

    &__wrapper {
        @apply relative rounded-2xl border border-gray-200 bg-white p-2 shadow-2xl;
    }

    &__items {
        @apply max-h-48 overflow-auto;
        &::-webkit-scrollbar {
            width: 3px; /* width of the entire scrollbar */
        }
        &::-webkit-scrollbar-track {
            background: #f8f7fc; /* color of the tracking area */
        }
        &::-webkit-scrollbar-thumb {
            background-color: #741fa2; /* color of the scroll thumb */
            border-radius: 10px; /* roundness of the scroll thumb */
        }
    }

    &__item {
        @apply flex w-full items-center space-x-2 rounded p-1 text-gray-800 transition-colors hover:text-site-primary lg:hover:bg-gray-50;
        img {
            @apply h-5;
        }
        &-text {
            @apply w-full items-start justify-start text-left text-base;
            p {
                @apply font-sans;
            }
        }
    }
}
</style>
