<!-- https://i.imgur.com/VS6Vhz5.png -->
<template>
    <div class="author">
        <NuxtLink no-prefetch :to="url">
            <MiscUserPic
                :class="imageClass || 'h-10 w-10'"
                :user="user"
                :badge="user.role.name === 'Usuario'"
            />
        </NuxtLink>
        <div>
            <NuxtLink no-prefetch :to="url" class="author__user" :class="isSharing ? '' : 'hover:underline'">
                {{ user.username }}
            </NuxtLink>
            <span v-if="user.role.name !== 'Usuario'" class="author__badge">
                <MiscBadge :text="user.role.name" :show-text-in-mobile="true" />
            </span>
        </div>
    </div>
</template>

<script lang="ts">
import type { Models } from '~/types/models'

export default defineComponent({
    name: 'MicroFullUser',
    props: {
        isSharing: {
            type: Boolean,
            default: false,
        },
        imageClass: {
            type: String,
        },
        user: {
            type: Object as PropType<Models.User>,
            required: true,
        },
    },
    computed: {
        url(): object | string {
            return this.isSharing
                ? '#'
                : {
                      name: 'dashboard-user',
                      params: { user: this.user.id },
                  }
        },
    },
})
</script>

<style lang="postcss" scoped>
.author {
    @apply flex w-full flex-row items-center gap-x-1.5;
    &__user {
        @apply ml-0.5 text-center text-xs sm:text-sm;
    }
    &__badge {
        @apply flex;
    }
}
</style>
