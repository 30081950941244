<template>
    <section class="share-offer-and-coupon">
        <div class="card_grid">
            <picture class="image-wrapper">
                <img
                    :src="
                        carddata.formData.imagesUploaded.length > 0
                            ? carddata.formData.imagesUploaded[carddata.formData.imageActive].dataUrl
                            : defaultImage
                    "
                    alt="image-uploaded"
                />
            </picture>

            <div class="middle-main-share">
                <DWidgetHeatVote
                    v-if="$config.public.variant === 'megadescuentos'"
                    maincard="true"
                    :count="0"
                />
                <div class="middle-main-share_header">
                    <a to="#" class="middle-main-share_header_title" rel="follow">
                        <p v-if="!carddata.formData.title">
                            {{ $lang.components.cardSharePreviewOfferAndCoupon.title }}
                            {{ type.wordSingular }}
                        </p>
                        <p v-else>{{ carddata.formData.title }}</p>
                        <span v-if="carddata.formData.new" class="middle-main-share_header_new-label">
                            {{ $lang.components.cardSharePreviewOfferAndCoupon.new }}
                        </span>
                    </a>
                </div>
                <div class="middle-main-share_header_description">
                    <div v-if="!carddata.formData.description && content">
                        <div v-if="carddata.kind === 'offer' || carddata.kind === 'coupon'">
                            {{ $lang.components.cardSharePreviewOfferAndCoupon.description1 }}
                            {{ type.wordSingular }}
                            {{ $lang.components.cardSharePreviewOfferAndCoupon.description2 }}
                        </div>
                    </div>
                    <div v-if="carddata.formData.description && content" class="line-clamp-2">
                        {{ content }}
                    </div>
                </div>
            </div>

            <div class="middle-main-share_prices-and-timeleft">
                <div class="middle-main-share_prices-and-timeleft_wrapper">
                    <div class="middle-main-share_prices-and-timeleft_wrapper_prices">
                        <span v-if="carddata.offer_details.price_offer" class="price">{{ priceOffer }}</span>
                        <span v-if="carddata.discount.type === 'precio'" class="price">{{
                            carddata.discount.value ? '$ ' + carddata.discount.value : ''
                        }}</span>
                        <div class="flex">
                            <span v-if="carddata.offer_details.regular_price" class="price-from">{{
                                priceRegular
                            }}</span>

                            <span
                                v-if="
                                    carddata.offer_details.price_offer && carddata.offer_details.regular_price
                                "
                                class="price-discount"
                                >{{ pricePercent }}</span
                            >

                            <span v-if="carddata.discount.type === 'porcentaje'" class="price-discount">{{
                                carddata.discount.value ? carddata.discount.value + '%' : ''
                            }}</span>
                        </div>
                        <span v-if="carddata.offer_details.ship_costs" class="free-ship"
                            ><img :src="$assets.gray.shipment" alt="icon" />{{
                                carddata.offer_details.ship_costs === '0' ||
                                carddata.offer_details.ship_costs === 0
                                    ? $lang.components.cardSharePreviewOfferAndCoupon.free_ship
                                    : $lang.symbol + carddata.offer_details.ship_costs
                            }}</span
                        >

                        <span v-if="carddata.discount.type === 'envío gratis'" class="free-ship"
                            ><img :src="$assets.gray.shipment" alt="icon-send" />
                            {{ $lang.components.cardSharePreviewOfferAndCoupon.free_ship }}
                        </span>
                    </div>

                    <div class="middle-main-share_prices-and-timeleft_wrapper_timeleft">
                        <div v-if="carddata.datePicker.start">
                            <img :src="$assets.yellow.offerStart" alt="icon-start" /><span
                                class="start-end-text"
                            >
                                {{ $lang.components.cardSharePreviewOfferAndCoupon.start_at }}
                            </span>
                            <span class="offer-time-text"
                                >{{
                                    new Intl.DateTimeFormat('es', {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit',
                                    }).format(carddata.datePicker.start)
                                }}
                            </span>
                        </div>
                        <div v-if="carddata.datePicker.end">
                            <img :src="$assets.blue.offerFinished" alt="icon-end-date" />
                            <span class="start-end-text">
                                {{ $lang.components.cardSharePreviewOfferAndCoupon.end_at }}
                            </span>
                            <span class="offer-time-text">
                                {{
                                    new Intl.DateTimeFormat('es', {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit',
                                    }).format(carddata.datePicker.end)
                                }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bottom-main-share">
                <MiscFullUser
                    v-if="AuthStore.UserData"
                    :user="AuthStore.UserData"
                    :badge="true"
                    :is-sharing="true"
                />

                <div class="button-offers">
                    <ButtonDiscount
                        :info="{
                            inCard: true,
                            mode: buttonType,
                            code: carddata.formData.code || 'Your code',
                            isOnlyToView: true,
                            discount: {} as any,
                        }"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { useAuthStore } from '~/store/auth'

import { useVariantsDictionary } from '~/composables/VariantsDictionary'
const { variants } = useVariantsDictionary()
const AuthStore = useAuthStore()
const { $lang, $assets } = useNuxtApp()

const props = defineProps({
    carddata: {
        type: Object as PropType<{
            kind: 'coupon' | 'offer' | 'chat'
            formData: any
            offer_details: any
            discount: any
            datePicker: any
        }>,
        default: () => {},
    },
    buttonType: {
        type: String as PropType<'coupon' | 'offer' | 'offer-code' | 'no-link-offer'>,
        default: '',
    },
})
const content = computed(() => {
    return props.carddata.formData.description.replace(/<[^>]*>?/gm, ' ') || ''
})

const type = variants[props.carddata.kind]

const defaultImage = computed(() => {
    return props.carddata.kind === 'offer'
        ? $assets.illustration.share.newoffer
        : $assets.illustration.share.newcoupon
})

const priceOffer = computed(() => {
    const price = props.carddata.offer_details.price_offer
    if (price === '0') {
        return $lang.components.cardSharePreviewOfferAndCoupon.free
    } else {
        return `$${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    }
})
const priceRegular = computed(() => {
    const price = props.carddata.offer_details.regular_price
    if (price === '0') {
        return $lang.components.cardSharePreviewOfferAndCoupon.free
    } else {
        return `$${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    }
})
const pricePercent = computed(() => {
    const formula = (
        (props.carddata.offer_details.price_offer * 100) /
        props.carddata.offer_details.regular_price
    ).toFixed(0)
    const percent = 100 - parseInt(formula)
    if (isNaN(percent)) {
        return ''
    } else if (
        props.carddata.offer_details.price_offer === '0' ||
        props.carddata.offer_details.regular_price === '0'
    ) {
        return ''
    }
    return ` - ${percent}%`
})
</script>

<style lang="postcss" scoped>
.share-offer-and-coupon {
    @apply mb-3 rounded-2xl border bg-white;
    .card_grid {
        @apply grid-cols-12;
        @apply grid items-center justify-center space-x-2 space-y-2 p-2;
        .image-wrapper {
            @apply col-span-4 row-span-2 mx-auto p-1.5 md:col-span-3 lg:col-span-4;
            img {
                @apply max-h-36 sm:max-h-40 lg:h-28;
            }
            /* bg-blue-500 */
        }
        .top {
            @apply col-span-12 col-start-1 row-span-1 md:col-span-9 md:col-start-4 lg:col-span-12 lg:col-start-1;
            @apply flex justify-between text-xs text-gray-800 lg:text-sm;
            .innerLeft {
                @apply flex items-start gap-3;
                div {
                    @apply flex gap-1;
                    span {
                        @apply self-center;
                    }
                    .icon {
                        @apply w-3 self-center;
                    }
                }
            }
            .innerRight {
                @apply flex w-1/2 flex-wrap justify-end gap-x-2 gap-y-1 sm:w-5/6;
                div {
                    @apply items-start gap-1 md:flex lg:block;
                    span {
                        @apply hidden self-center md:block lg:hidden;
                    }
                    .icon {
                        @apply hidden h-3 self-center md:block lg:hidden;
                    }
                }
                .icon-menu {
                    @apply relative flex h-6 w-6 justify-center md:hidden lg:hidden;
                    img {
                        @apply h-4;
                    }
                }
            }
        }

        .middle-main-share {
            @apply col-span-12 col-start-5 row-span-1 md:col-span-9 md:col-start-4 lg:col-span-12 lg:col-start-5;
            @apply space-y-2;
            &_header {
                @apply items-center gap-4;
                &_title {
                    @apply line-clamp-2 gap-2 text-[16px] font-semibold text-gray-800 hover:underline;
                }
                &_see-more-offers {
                    @apply gap-1 text-sm;
                    &_text {
                        @apply text-gray-800;
                    }
                    &_text-2 {
                        @apply font-bold text-site-primary hover:underline;
                    }
                }
                &_new-label {
                    @apply self-center rounded-lg bg-site-primary px-2 text-xs text-white;
                }
                &_description {
                    @apply hidden text-[14px] sm:block;
                    div {
                        @apply break-words text-gray-800;
                    }
                    a {
                        @apply font-semibold hover:underline;
                    }
                }
            }
            &_prices-and-timeleft {
                @apply col-span-12 col-start-5 row-span-1 space-x-3 md:col-span-9 md:col-start-4 lg:col-span-12 lg:col-start-5;
                &_wrapper {
                    @apply flex w-full flex-wrap gap-2 text-base;
                    &_prices {
                        @apply flex flex-wrap gap-1 text-lg md:gap-x-2;
                        .price {
                            @apply self-center font-semibold;
                        }
                        .price-from {
                            @apply self-center text-gray-800 line-through;
                        }
                        .price-discount {
                            @apply self-center whitespace-pre font-semibold text-site-primary;
                        }
                        .free-ship {
                            @apply flex flex-nowrap gap-1 self-center text-sm text-gray-800;
                            img {
                                @apply h-3 self-center;
                            }
                        }
                    }
                    &_timeleft {
                        @apply -ml-2.5 flex flex-wrap gap-x-2 self-center text-xs sm:ml-0;
                        div {
                            @apply flex gap-1;
                            img {
                                @apply h-3 self-center;
                            }
                            .start-end-text {
                                @apply whitespace-nowrap text-gray-800;
                            }
                            .offer-time-text {
                                @apply whitespace-nowrap font-medium text-gray-800;
                            }
                        }
                    }
                }
            }
        }

        .bottom-main-share {
            @apply col-span-12 row-span-1 block justify-between space-y-2 md:col-span-9 md:col-start-4 md:flex md:space-y-0 lg:col-span-12 lg:col-start-1;
            .button-offers {
                @apply flex shrink gap-4 md:w-96 md:justify-end lg:mt-2 xl:mt-0;
                > div {
                    @apply lg:w-full;
                }
            }
        }
    }
}
</style>
