<template>
    <div class="fields-tabs">
        <div class="fields-tabs_wrapper">
            <div class="fields-tabs_wrapper-box">
                <button
                    type="button"
                    class="fields-tabs_button"
                    :class="activePercent"
                    @click="handleActive('porcentaje')"
                >
                    <span class="symbol">%</span> {{ $lang.components.formFieldTabs.percent }}
                </button>
                <button
                    type="button"
                    class="fields-tabs_button"
                    :class="activeCash"
                    @click="handleActive('precio')"
                >
                    <span class="symbol">{{ $lang.symbol }}</span>
                    {{ $lang.components.formFieldTabs.pesos }}
                </button>
                <button
                    type="button"
                    class="fields-tabs_button"
                    :class="activeShipping"
                    @click="handleActive('envío gratis')"
                >
                    <img
                        :src="active !== 'envío gratis' ? $assets.primary.ship : $assets.white.ship"
                        alt=""
                    />
                    {{ $lang.components.formFieldTabs.free_ship }}
                </button>
            </div>
            <div
                v-if="!activeNull.active"
                class="fields-tabs_wrapper-tab"
                :class="pos"
                @click="() => (active = undefined)"
            ></div>
        </div>

        <div v-if="active === 'porcentaje' || active === 'precio'" class="fields-tabs_input">
            <div class="title">
                {{ active === 'porcentaje' ? '%' : 'Pesos' }}
                {{ $lang.components.formFieldTabs.discount }}
                <span class="optional"> ({{ $lang.components.formFieldTabs.required }}) </span>
            </div>

            <InputGeneric
                @update:model-value="test"
                :image="active === 'porcentaje' ? $assets.primary.percent : $assets.primary.cash"
                imagestyle="top-2 mt-0.5"
                bgcolor="light"
                placeholder="0"
                :invalid="invalid"
            >
                {{ $lang.components.formFieldTabs.required_discount }}
            </InputGeneric>
        </div>
    </div>
</template>

<script lang="ts">
export default defineComponent({
    name: 'FieldTabs',
    props: {
        optional: {
            type: Boolean,
        },
        invalid: {
            // type can be boolean or string
            type: [Boolean, String],
        },
    },
    emits: ['change'],
    data() {
        return {
            active: undefined as any,
            discount_value: undefined as any,
            tabs: ['porcentaje', 'precio', 'envío gratis', undefined],
        }
    },
    computed: {
        pos(): {
            left: boolean
            center: boolean
            right: boolean
            none: boolean
        } {
            return {
                left: this.active === this.tabs[0],
                center: this.active === this.tabs[1],
                right: this.active === this.tabs[2],
                none: this.active === this.tabs[3],
            }
        },
        activePercent(): { active: boolean } {
            return {
                active: this.active === 'porcentaje',
            }
        },
        activeCash(): { active: boolean } {
            return {
                active: this.active === 'precio',
            }
        },
        activeShipping(): { active: boolean } {
            return {
                active: this.active === 'envío gratis',
            }
        },
        activeNull(): { active: boolean } {
            return {
                active: this.active === undefined,
            }
        },
    },
    watch: {
        active(newValue: string | null | undefined) {
            this.discount_value = undefined

            if (newValue === 'envío gratis') {
                this.$emit('change', {
                    type: 'envío gratis',
                    value: null,
                })
            } else {
                this.$emit('change', {
                    type: newValue,
                    value: null,
                })
            }
        },
        discount_value: {
            immediate: true,
            handler(val) {
                if (!val) {
                    this.$emit('change', {
                        type: this.active,
                        value: null,
                    })
                    return
                }
                if (this.active === 'porcentaje') {
                    const newValue = val.replace(/\D/g, '')
                    if (Number(newValue) > 100) {
                        this.discount_value = '100'
                        this.$emit('change', {
                            type: this.active,
                            value: this.discount_value,
                        })
                    } else {
                        this.discount_value = newValue
                        this.$emit('change', {
                            type: this.active,
                            value: this.discount_value,
                        })
                    }
                } else {
                    const newValue = val.replace(/\D/g, '')
                    this.discount_value = newValue
                    this.$emit('change', {
                        type: this.active,
                        value: this.discount_value,
                    })
                }
            },
        },
    },
    methods: {
        handleActive(type: string) {
            if (this.active === type) {
                this.active = undefined
            } else {
                this.active = type
            }
        },
        test(e: any) {
            this.discount_value = e
        },
    },
})
</script>

<style lang="postcss" scoped>
.fields-tabs {
    &_wrapper {
        @apply relative w-full overflow-hidden rounded-xl border text-site-primary;
        &-box {
            @apply flex justify-around overflow-hidden rounded bg-[#F8F7FC];
        }
        &-tab {
            @apply absolute left-auto top-0 h-full w-1/3 bg-site-primary transition-all md:rounded;
            &.left {
                @apply left-0;
            }
            &.center {
                @apply left-1/3;
            }
            &.right {
                @apply left-2/3;
            }
        }
    }
    &_input {
        @apply mt-4;
        .title {
            @apply mb-2 flex items-center gap-1 text-lg font-semibold text-gray-800;
            .optional {
                @apply text-sm font-medium text-site-primary;
            }
        }
    }
    &_button {
        @apply relative z-10 inline-block w-1/3 cursor-pointer items-center justify-center gap-2 border-r bg-transparent py-2 text-sm transition-colors sm:flex;
        img {
            @apply mx-auto self-center sm:mx-0;
        }
        .symbol {
            @apply inline;
        }
        &.active {
            @apply text-white;
        }
    }
    &__error {
        @apply mt-2 flex items-center space-x-1.5 text-sm text-red-500;
        img {
            @apply w-4;
        }
    }
}
.invalid {
    @apply border-red-500 ring-2 ring-red-500;
}
</style>
