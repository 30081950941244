<template>
    <div>
        <div class="field-quantity" :class="{ disabled: isDisabled }">
            <div
                class="field-quantity_box"
                :class="[
                    { invalid },
                    isFocus[0] ? 'bg-site-primary text-white' : 'bg-[#F8F7FC]',
                    discountLocked ? 'bg-[#f8f7fc80]' : '',
                ]"
            >
                <div
                    class="head"
                    :class="[
                        isFocus[0] ? 'text-white' : 'bg-[#F8F7FC]',
                        discountLocked ? 'bg-[#f8f7fc80] text-gray-800' : '',
                    ]"
                >
                    {{ $lang.components.formFieldPrice.offer_price }}
                    <span
                        :class="[
                            isFocus[0] ? 'text-gray-200' : 'text-gray-800',
                            discountLocked ? 'text-gray-800' : '',
                        ]"
                        >({{ $lang.components.formFieldPrice.optional }})</span
                    >
                </div>
                <div
                    class="input-wrap"
                    :class="[isFocus[0] ? 'text-white' : '', discountLocked ? 'text-gray-800' : '']"
                >
                    $<input
                        :disabled="discountLocked"
                        type="number"
                        :value="priceoffer"
                        placeholder="0"
                        :class="[
                            [
                                isFocus[0]
                                    ? 'bg-site-primary text-white placeholder:text-gray-200'
                                    : 'bg-[#F8F7FC]',
                                discountLocked ? 'bg-[#f8f7fc80] text-gray-800' : '',
                            ],
                        ]"
                        @input="updatePriceoffer"
                        @focus="handleFocus(0, true)"
                        @blur="handleFocus(0, false)"
                    />
                </div>
            </div>

            <div
                class="field-quantity_box"
                :class="[
                    { invalid },
                    isFocus[1] ? 'bg-site-primary text-white' : 'bg-[#F8F7FC]',
                    discountLocked ? 'bg-[#f8f7fc80]' : '',
                ]"
            >
                <div
                    class="head"
                    :class="[
                        isFocus[1] ? 'text-white' : 'bg-[#F8F7FC]',
                        discountLocked ? 'bg-[#f8f7fc80] text-gray-800' : '',
                    ]"
                >
                    {{ $lang.components.formFieldPrice.regular_price }}
                    <span
                        :class="[
                            isFocus[1] ? 'text-gray-200' : 'text-gray-800',
                            discountLocked ? 'text-gray-800' : '',
                        ]"
                    >
                        ({{ $lang.components.formFieldPrice.optional }})
                    </span>
                </div>
                <div
                    class="input-wrap"
                    :class="[isFocus[1] ? 'text-white' : '', discountLocked ? 'text-gray-800' : '']"
                >
                    $<input
                        :disabled="discountLocked"
                        :value="priceregular"
                        placeholder="0"
                        type="number"
                        :class="[
                            [
                                isFocus[1]
                                    ? 'bg-site-primary text-white placeholder:text-gray-200'
                                    : 'bg-[#F8F7FC]',
                                discountLocked ? 'bg-[#f8f7fc80] text-gray-800' : '',
                            ],
                        ]"
                        @input="updatePriceregular"
                        @focus="handleFocus(1, true)"
                        @blur="handleFocus(1, false)"
                    />
                </div>
            </div>

            <div
                class="field-quantity_box relative"
                :class="[
                    isFocus[2] ? 'bg-site-primary text-white' : 'bg-[#F8F7FC]',
                    costshipLocked ? 'bg-[#f8f7fc80]' : '',
                ]"
            >
                <div
                    class="head"
                    :class="[
                        isFocus[2] ? 'text-white' : 'bg-[#F8F7FC]',
                        costshipLocked ? 'bg-[#f8f7fc80] text-gray-800' : '',
                    ]"
                >
                    {{ $lang.components.formFieldPrice.ship_price }}
                    <span
                        :class="[
                            isFocus[2] ? 'text-gray-200' : 'text-gray-800',
                            costshipLocked ? 'text-gray-800' : '',
                        ]"
                        >({{ $lang.components.formFieldPrice.optional }})</span
                    >
                </div>
                <div
                    class="input-wrap"
                    :class="[isFocus[2] ? 'text-white' : '', costshipLocked ? 'text-gray-800' : '']"
                >
                    $<input
                        :disabled="costshipLocked"
                        :value="shipcost"
                        type="number"
                        placeholder="0"
                        :class="[
                            isFocus[2]
                                ? 'bg-site-primary text-white placeholder:text-gray-200'
                                : 'bg-[#F8F7FC]',
                            costshipLocked ? 'bg-[#f8f7fc80] text-gray-800' : '',
                        ]"
                        @input="updateShipcost"
                        @focus="handleFocus(2, true)"
                        @blur="handleFocus(2, false)"
                    />
                </div>
            </div>
        </div>
        <div class="field-quantity__checkboxes">
            <InputCheckbox v-model="freediscount">
                {{ $lang.components.formFieldPrice.free_promotion }}
            </InputCheckbox>
            <InputCheckbox v-model="freeshiping">
                {{ $lang.components.formFieldPrice.free_ship }}
            </InputCheckbox>
        </div>
        <div v-if="invalid && typeof invalid === 'string'" class="field-quantity__error">
            <img :src="$assets.red.formError" alt="icon" />
            <p>{{ $lang.components.formFieldPrice.price_offer_higher_than_regular }}</p>
        </div>
    </div>
</template>

<script lang="ts">
export default defineComponent({
    name: 'FieldPrice',
    props: {
        isDisabled: {
            type: Boolean,
        },
        priceoffer: {
            type: String,
        },
        priceregular: {
            type: String,
        },
        shipcost: {
            type: String,
        },
        freeship: {
            type: Boolean,
        },
        invalid: {
            type: [Boolean, String],
            default: false,
        },
    },
    data() {
        return {
            isFocus: [false],
            freeshiping: false,
            freediscount: false,
            costshipLocked: false,
            discountLocked: false,
        }
    },
    watch: {
        freediscount: {
            handler(newval): void {
                if (newval) {
                    this.$emit('update:priceoffer', '0')
                    this.$emit('update:priceregular', '')
                    this.discountLocked = true
                } else {
                    this.discountLocked = false
                    this.$emit('update:priceoffer', '')
                    this.$emit('update:priceregular', '')
                }
            },
            immediate: true,
        },
        freeshiping: {
            handler(newval): void {
                if (newval) {
                    this.$emit('update:shipcost', '0')
                    this.costshipLocked = true
                } else {
                    this.$emit('update:freeship', newval)
                    this.$emit('update:shipcost', '')
                    this.costshipLocked = false
                }
            },
            immediate: true,
        },
        priceoffer: {
            handler(newval): void {
                if (newval === '0' && this.priceregular === '0') {
                    this.freediscount = true
                }
                this.$emit('update:priceoffer', newval.replace(/\D/g, ''))
            },
        },
        priceregular: {
            handler(newval): void {
                if (newval === '0' && this.priceoffer === '0') {
                    this.freediscount = true
                }
                this.$emit('update:priceregular', newval.replace(/\D/g, ''))
            },
        },
        shipcost: {
            handler(newval): void {
                if (this.freeshiping === true) {
                    this.$emit('update:shipcost', '0')
                } else {
                    const newValue = newval.replace(/\D/g, '')
                    this.$emit('update:shipcost', newValue)
                }
            },
        },
    },
    methods: {
        handleFocus(key: number, value: boolean): void {
            const newFocus = this.isFocus.map((val) => val)

            newFocus[key] = value

            this.isFocus = newFocus
        },
        updatePriceoffer(e: any) {
            this.$emit('update:priceoffer', e.target.value)
        },
        updatePriceregular(e: any) {
            this.$emit('update:priceregular', e.target.value)
        },
        updateShipcost(e: any) {
            this.$emit('update:shipcost', e.target.value)
        },
    },
})
</script>

<style lang="postcss" scoped>
.field-quantity {
    @apply mb-5 flex gap-4;
    &.disabled {
        @apply opacity-60 grayscale filter;
    }
    &_box {
        @apply flex w-[29%] flex-col space-y-2 rounded-xl p-2 text-gray-800;
        .head {
            @apply text-sm font-medium;
            span {
                @apply font-sans text-xs;
            }
        }
        .input-wrap {
            @apply flex items-center gap-1;
            input {
                @apply w-full max-w-max appearance-none border-none p-0 text-lg outline-none focus:outline-none focus:ring-0;
            }
            input[type='number']::-webkit-inner-spin-button,
            input[type='number']::-webkit-outer-spin-button {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                margin: 0;
            }
        }
    }
    &__checkboxes {
        @apply space-y-1;
    }
    &__error {
        @apply mt-2 flex items-center space-x-1.5 text-sm text-red-500;
        img {
            @apply w-4;
        }
    }
}
.invalid {
    @apply border-red-500 ring-2 ring-red-500;
}
</style>
