<template>
    <div class="chat-share">
        <h3>
            {{
                carddata.formData.title
                    ? carddata.formData.title
                    : $lang.components.cardSharePreviewChat.title
            }}
            <p>
                {{
                    dropdownSelected !== $lang.components.cardSharePreviewChat.select
                        ? dropdownSelected
                        : $lang.components.cardSharePreviewChat.select_category
                }}
            </p>
        </h3>

        <h5>
            {{
                carddata.formData.description && content
                    ? content
                    : $lang.components.cardSharePreviewChat.description
            }}
        </h5>
        <MiscFullUser v-if="AuthStore.UserData" :user="AuthStore.UserData" :badge="true" :is-sharing="true" />
    </div>
</template>

<script lang="ts" setup>
import { useAuthStore } from '~/store/auth'

const AuthStore = useAuthStore()

const props = defineProps({
    carddata: {
        type: Object,
        default: () => {},
    },
    dropdownSelected: {
        type: String,
        required: true,
    },
})
const content = computed(() => {
    return props.carddata.formData.description.replace(/<[^>]*>?/gm, ' ') || ''
})
</script>

<style lang="postcss" scoped>
.chat-share {
    @apply mt-4 h-fit space-y-3 rounded-xl bg-white p-3 text-gray-800 lg:col-span-5 lg:mt-0;
    h3 {
        @apply text-lg font-semibold;
        p {
            @apply font-sans text-base text-gray-800;
        }
    }
    h5 {
        @apply break-words font-sans text-gray-800;
    }
    .action {
        @apply flex w-full items-center justify-center gap-2 rounded-xl bg-site-primary p-2 px-3 text-white sm:w-fit sm:justify-start;
        img {
            @apply h-4;
        }
    }
}
</style>
